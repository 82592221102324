@font-face {
  font-family: 'Futura medium';
  src: url(./futura-medium.otf); }

body {
  padding: 0;
  font-family: 'Futura medium', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #C2B4A7;
  letter-spacing: 2px;
  line-height: 24px; }

html, body, #root {
  height: 100%;
  margin: 0; }

button, .btn {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  padding: 8px;
  cursor: pointer;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  text-decoration: none;
  &:hover {
    background-color: white;
    border-color: white;
    color: black; } }

.logo {
  width: 57vw;
  height: 37vh;
  margin-top: -60px;
  @media only screen and (min-width: 1000px) {
    width: 60vw;
    height: 40vh;
    margin-top: -50px; } }

footer {
  background-color: #B32D32;
  animation: background-loop 10s infinite;
  min-height: 40vh;
  @media only screen and (min-width: 600px) {
    min-height: 60px; } }

@media only screen and (min-width: 600px) {
  .hidden-sm-up {
    height: 0; } }

address {
  font-style: normal;
  font-size: 17px;
  @media only screen and (min-width: 600px) {
    font-size: 21px; } }

@keyframes background-loop {
  0% { background-color: #B32D32 };
  33% { background-color: #686B60 };
  66% { background-color: #AA8174 }; }

@media only screen and (max-width: 1000px) {
  .background {
    background-size: cover; } }
